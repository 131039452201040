import { Expose } from "class-transformer";

export default class ModelProjectAccount {
    @Expose({ name: 'login' })
    private readonly _login: string;

    @Expose({ name: 'lang'})
    private readonly _lang: string;

    @Expose({ name: 'passwordIsSet' })
    private readonly _passwordIsSet: boolean;

    @Expose({name: 'currency'})
    private readonly _currency: string;

    @Expose({ name: 'phone' })
    private readonly _phone: string;

    constructor(
        login: string,
        lang: string,
        currency: string,
        passwordIsSet: boolean,
        phone: string,
    ) {
        this._login = login;
        this._lang = lang;
        this._currency = lang;
        this._passwordIsSet = passwordIsSet;
        this._phone = phone;
    }

    public get login() {
        return this._login;
    }

    public get currency() {
        return this._currency;
    }

    public get lang() {
        return this._lang;
    }

    public get passwordIsSet() {
        return this._passwordIsSet;
    }

    public get phone() {
        return this._phone;
    }
}

<template>
    <OperationCard
        :operation="operation"
        @toOperation="toOperation"
        :mainAmount="operation.amountTotal"
        :mainAmountAbbr="operation.currency.abbr"
        :convertAmount="operation.amountFiatTotal"
        :convertAmountText="$t('withdrawal.wallet.operation-text')"
    >
        <template #icon>
            <i class="icon round-arrows-svg"></i>
        </template>
    </OperationCard>
</template>

<script setup lang="ts">
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import OperationCard from "@components/atomic/operations/cards/atomic/OperationCard.vue";

interface Emits {
    (e: 'toOperation'): void,
}

interface Props {
    operation: WithdrawalWalletOperation
}

const emits = defineEmits<Emits>();

defineProps<Props>();

const toOperation = () =>{
    emits("toOperation");
}
</script>

<template>
    <f7-page
        ref="self"
        :page-content="false"
        class="withdrawal-wallet-page"
        :no-navbar="pageOpenIn === PageOpenIn.POPUP"
        :class="{'without-navbar': pageOpenIn === PageOpenIn.POPUP}"
        @page:beforein="pageBeforeIn"
    >
        <f7-navbar class="withdrawal-wallet-page__navbar">
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="routerService.toBack">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>{{ $t('withdrawal.wallet.navbar.title') }}</f7-nav-title>
            <f7-nav-right>

            </f7-nav-right>
        </f7-navbar>
        <f7-page-content ptr @ptr:refresh="refreshPage">
            <popup-navbar
                :title="$t('withdrawal-create.title')"
                v-if="pageOpenIn === PageOpenIn.POPUP"
                @on-left-click="routerService.toBack"
            />
            <template v-if="withdrawal">
                <div class="withdrawal-wallet-page_amount">
                    <p>{{ amountWithFee }} USDT</p>
                </div>
                <div class="withdrawal-wallet-page_data">
                    <div class="withdrawal-wallet-page_psystem">
                        <p class="withdrawal-wallet-page_title">{{ $t("withdrawal-created.network") }}</p>
                        <div class="withdrawal-wallet-page_text-wrapper">
                            <img :src="withdrawal.psystem.imgPath!" alt="">
                            <p class="withdrawal-wallet-page_text">
                                {{ withdrawal.psystem.name }}
                            </p>
                        </div>
                    </div>
                    <div class="withdrawal-wallet-page_status">
                        <p class="withdrawal-wallet-page_title">{{ $t("withdrawal.wallet.status") }}</p>
                        <OperationStatus :operation="withdrawal" />
                    </div>
                    <div class="withdrawal-wallet-page_address">
                        <p class="withdrawal-wallet-page_title">{{ $t("withdrawal-created.address") }}</p>
                        <p class="withdrawal-wallet-page_text">{{ withdrawal.payData.requisites.address }}</p>
                    </div>
                </div>
                <div
                    class="withdrawal-wallet-page_external-link-wrapper"
                >
                    <a
                        target=”_blank”
                        v-if="withdrawal.status.toLowerCase() === WithdrawalWalletOperationStatus.COMPLETED"
                        :href="withdrawal.payData.checkupUrl"
                        class="withdrawal-wallet-page_external-link external"
                    >{{ $t("withdrawal.wallet.link") }}</a>
                </div>
            </template>
        </f7-page-content>

    </f7-page>
</template>

<script setup lang="ts">
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {PageOpenIn} from "@enums/PageOpenIn";
import PopupNavbar from "@components/popup/popup-navbar/PopupNavbar.vue";
import RouterService from "@/services/RouterService";
import {computed, ref} from "vue";
import {f7} from "framework7-vue";
import WithdrawalWalletPageController from "@/views/withdrawal-group/wallet/wallet/WithdrawalWalletPageController";
import {
    WithdrawalWalletOperationStatus
} from "@models/operations/withdrawal/wallet/WithdrawalWalletOperationStatus";
import OperationStatus from "@components/atomic/operations/status/OperationStatus.vue";

const self = ref(null);
const routerService: RouterService = AppController.getInstance().routerService;
const controller = WithdrawalWalletPageController.of();
const {withdrawal, pageOpenIn} = controller;
const amountWithFee = computed(() => {
    return withdrawal.value!.amountTotal
});
function closePopup() {
    // @ts-ignore
    const popupEl: HTMLDivElement = self.value.$el.closest('.withdrawal-popup')!;
    f7.popup.get(popupEl).close();
}
async function refreshPage(done: Function) {
    try {
        await controller.updateWithdrawal();
    } catch (e: any) {

    } finally {
        setTimeout(() => {
            done();
        }, 314)
    }
}
function pageBeforeIn() {
}
</script>

<style lang="scss">
.withdrawal-wallet-page {
    &_hint {
        width: calc(100% - 32px);
        display: flex;
        gap: 12px;
        margin-top: 24px;
        margin-left: 16px;
        padding: 12px;
        border-radius: 12px;
        background-color: rgba(230, 151, 0, 0.1);

        p {
            @include text(14px, 400, #CF7529, 16.8px);
        }
    }

    &_external-link {
        @include text(14px, 400, var(--blue1), 16.8px);
    }

    &_external-link-wrapper {
        margin-top: 24px;
        display: flex;
        justify-content: center;
    }

    &_actions {
        margin-top: 32px;
    }

    &.without-navbar {
        --f7-page-content-extra-padding-top: 24px;
    }

    &_psystem {
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &_status {
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: 0;
            right: auto;
            bottom: auto;
            left: 16px;
            height: 1px;
            width: calc(100% - 16px);
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }

    &_address {
        padding: 10px 16px;
        //align-items: flex-start;
        //gap: 8px;
        //flex-direction: column;
        position: relative;

        p:nth-child(2) {
            margin-top: 8px;
            word-break: break-all;
        }

        &::before {
            content: "";
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: 0;
            right: auto;
            bottom: auto;
            left: 16px;
            height: 1px;
            width: calc(100% - 16px);
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }

    &_text-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
            width: 24px;
        }
    }

    &_title {
        @include text(16px, 400, var(--grey2), 24px);
    }

    &_text {
        @include text(16px, 500, var(--brown1), 24px);
    }

    &_amount {
        margin-top: 32px;
        margin-bottom: 32px;
        display: flex;
        justify-content: center;

        p {
            @include text(28px, 700, var(--brown1), 33.6px);
        }
    }

    &_data {
        position: relative;
        background: #FFF;

        &::before {
            content: "";
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: 0;
            right: auto;
            bottom: auto;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }

        &::after {
            content: "";
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }
}
</style>

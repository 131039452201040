<template>
    <f7-navbar class="payments-view__navbar">
        <f7-nav-left>
            <a href="#" class="link icon-only" :class="{'disabled': !account }" @click="TO_SETTINGS">
                <i class="icon i-svg-settings"></i>
            </a>
        </f7-nav-left>
        <f7-nav-title>
            <TransitionGroup name="list" tag="div">
                <i ref="sloth-m" class="icon sloth-logo-m-svg"/>
                <i v-if="!viewNavbarLogo" class="icon logo-small-svg"/>
            </TransitionGroup>
        </f7-nav-title>
        <f7-nav-right>
            <Transition name="fade" mode="out-in">
                <!--                <template v-if="viewNavbarBalance">-->
                <a href="#" class="link icon-only" @click="openAlertToTelegram">
                    <i class="icon telegram-svg"></i>
                </a>
                <!--                    <a href="#" class="link icon-only" :class="{'disabled': nextbtnLoading}" @click="openSheetModalForNewPayment">-->
                <!--                        <i class="icon plus-svg"></i>-->
                <!--                    </a>-->
                <!--                    <div class="balance-box" :class="{'disabled': nextbtnLoading}" @click="openSheetModalForNewPayment">-->
                <!--                        <i class="icon green-round-plug-svg"></i>-->
                <!--                        <div class="balance-lines">-->
                <!--                            <p class="main">{{ account.balance.amountC }} {{ account.currency.abbr }}</p>-->
                <!--                            <p class="usdt">{{ account.balance.amount }} USDT</p>-->
                <!--                        </div>-->
                <!--                    </div>-->
            </Transition>
        </f7-nav-right>
    </f7-navbar>
</template>

<script lang="ts">
import {defineComponent, ref, toRef} from "vue";
import {f7, useStore} from "framework7-vue";
import {getDevice} from "framework7/shared/get-device";
import NavbarTitleContent from "@/targets/integration/views/payments/components/NavbarTitleContent.vue";
// @ts-ignore
import anime from "animejs";
import {useI18n} from "vue-i18n";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";

export default defineComponent({
    components: {NavbarTitleContent},
    computed: {},
    props: ['viewNavbarBalance', 'viewNavbarLogo'],
    setup(props: any) {
        const account = ServiceAccount.of().account;
        const nextbtnLoading = ref(false);
        const viewNavbarBalance = toRef(props, 'viewNavbarBalance')
        const viewNavbarLogo = toRef(props, 'viewNavbarLogo')
        const needHideLogoM = ref(false);
        const allowAnimateShow = ref(true);
        const {t} = useI18n({useScope: 'global'});

        return {
            t,
            allowAnimateShow,
            needHideLogoM,
            viewNavbarLogo,
            viewNavbarBalance,
            nextbtnLoading,
            account,
            getDevice
        }
    },
    watch: {},
    methods: {
        openAlertToTelegram() {
            f7.dialog.create({
                title: this.t("g.alerts.to-telegram.title"),
                text: this.t("g.alerts.to-telegram.text"),
                cssClass: "simple",
                buttons: [
                    {
                        text: this.t("g.no")
                    },
                    {
                        text: this.t("g.yes"),
                        onClick: async () => {
                            const url = "https://t.me/BroMoney_robot";
                            if (getDevice().cordova) {
                                // ts-ignore
                                window.cordova.InAppBrowser.open(url, '_system');
                            } else {
                                try {
                                    if (getDevice().ios) {
                                        // @ts-ignore
                                        window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                                            "deeplink": "https://t.me/BroMoney_robot"
                                        }));
                                    } else if (getDevice().android) {
                                        // @ts-ignore
                                        window.webInterface.onMessage(JSON.stringify({
                                            "deeplink": "https://t.me/BroMoney_robot"
                                        }));
                                    } else {
                                        window.open(url, "_target");
                                    }
                                } catch (e) {
                                    window.open('https://t.me/BroMoney_robot', "_target");
                                }
                            }
                        }
                    }
                ]
            }).open();
        },
        TO_SETTINGS() {
            f7.views.current.router.navigate('/settings');
        },
        openSheetModalForNewPayment() {
            this.nextbtnLoading = true;
            this.$emit('openSheetModalForNewPayment', () => {
                this.nextbtnLoading = false;
            });
        },
        animateLogo(init: boolean) {
            const element = this.$refs['sloth-m'];
            let title: HTMLElement | null = null;
            title = document.querySelector('.payments-view__navbar .title')!;
            const m = title.offsetWidth / 2 - 17;

            if (init) {
                // if (!this.allowAnimateShow) return;
                // this.allowAnimateShow = false;
                anime({
                    targets: element,
                    keyframes: [
                        {
                            // translateX: m
                        },
                    ],
                    duration: 700,
                    // update: () => {
                    //     if (this.needHideLogoM) {
                    //         element.style.opacity = 0;
                    //         element.style.transform = `unset`;
                    //     }
                    // },
                    // complete: () => {
                    //     this.allowAnimateShow = true;
                    // }
                });
            } else {
                anime({
                    targets: element,
                    keyframes: [
                        {
                            // translateX: 0
                        },
                    ],
                    duration: 700,
                });
                // this.needHideLogoM = true;
                // element.style.opacity = 0;
                // element.style.transform = `unset`;
                // setTimeout(() => {
                //     this.needHideLogoM = false;
                // }, 701);
            }
        }
    }
});
</script>

<style lang="scss">
.payments-view__navbar {
    .subnavbar-left {
        flex: 1;
    }

    .subnavbar-right {
        flex: 1;
        display: flex;
        justify-content: flex-end
    }
}
</style>

// @ts-ignore
import routes from "@target/core/routes";
import MainApiService from "@/services/api-service/MainApiService";
import LangService from "@/services/LangService";
import {ref, Ref, ToRef} from "vue";
import SocketService from "@/services/SockerService";
import PaymentService from "@/services/operations/payment/PaymentService";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import RouterService from "@/services/RouterService";
import {f7} from "framework7-vue";
import {getDevice} from "framework7/shared/get-device";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import LogService from "@/services/log-service/LogService";
import BaseAppController from "@/controllers/app/BaseAppController";
import AuthDto from "@/targets/main/components/App/ts/dto/AuthDto";
import AuthorizationService from "@/services/authorization/AuthorizationService";
import FirebaseFcmToken from "@models/firebase/FirebaseFcmToken";
import generateHash from "@/helpers/hash-function";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import DocumentOthers from "@/services/firebase/firestore/documents/DocumentOthers";
import ProjectApiService from "@/targets/main/services/project/projectApiService";
import kodmobiApiService from "@/targets/main/services/project/kodmobiApiService";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ServiceDI from "@/services/v2/service-DI/ServiceDI";
import ServiceReleazio from "@/services/v2/service-releazio/ServiceReleazio";
import ServiceReleazioApi from "@/services/v2/service-releazio-api/ServiceReleazioApi";
import RepositoryReleazio from "@/repositories/v2/repository-releazio/RepositoryReleazio";
import ServicePaymentIncome from "@/services/v2/data/service-payment-income/ServicePaymentIncome";
import ServiceProject from "@/services/v2/data/service-project/ServiceProject";
import ServiceFetchOperation from "@/services/v2/data/service-fetch-operation/ServiceFetchOperation";
import RepositoryFetchOperation from "@/repositories/v2/repository-fetch-operation/RepositoryFetchOperation";
import ServiceTelegram from "@/services/v2/service-telegram/ServiceTelegram";
import ServiceOperations from "@/services/v2/data/service-operations/ServiceOperations";

type PaymentRequestData = { amount: number, psystem_id: number, currency_id?: number, type?: 'purchase' | 'sale' }

export default class AppController extends BaseAppController {
    private static instance: AppController | null = null;
    private _authorizationService: AuthorizationService = new AuthorizationService();
    readonly config: object;
    private _isAuth: Ref<Boolean> = ref(false);
    private _isReady: Ref<Boolean> = ref(false);
    public socketIsConnecting: Ref<boolean> = ref(false);
    private _paymentRequestData: ToRef<PaymentRequestData | null>;
    private _paymentService: PaymentService;
    private _withdrawalService: WithdrawalService;
    private _routerService: RouterService;
    private _serviceAccount: ServiceAccount;
    private _serviceOperations: ServiceOperations;
    private _serviceProject: ServiceProject;
    public yandexCaptchaVisible = ref(false);
    public yandexCaptchaID = ref(null);

    constructor() {
        super();
        this.config = {
            name: 'app',
            theme: 'ios',
            routes: routes,
            iosTranslucentBars: false,
            input: {
                scrollIntoViewOnFocus: true,
                scrollIntoViewCentered: true,
            },
            statusbar: {
                iosOverlaysWebView: false,
                androidOverlaysWebView: false,
            },
        }
        const locale = localStorage.getItem('lang');
        if (locale) {
            LangService.getInstance().set(locale);
        }
        this._paymentRequestData = ref(null);
        this._paymentService = new PaymentService();
        this._withdrawalService = new WithdrawalService();
        this._routerService = new RouterService();
        this._serviceAccount = ServiceAccount.of();
        this._serviceOperations = ServiceOperations.of();
        this._serviceProject = ServiceProject.of();
    }

    public async initCaptcha(key: string, callback: Function) {
        // @ts-ignore
        if (window.smartCaptcha) {
            this.yandexCaptchaVisible.value = true;
            setTimeout(() => {
                // @ts-ignore
                this.yandexCaptchaID.value = window.smartCaptcha.render("captcha-container", {
                    sitekey: key,
                    callback: (token: string) => {
                        callback(token);
                        this.yandexCaptchaVisible.value = false;
                    },
                });
            }, 0);
        } else {
            console.error("SmartCaptcha не загружена");
        }
    }

    protected createServiceDI() {
        return ServiceDI.builder()
            .addPack([
                {
                    key: "releazio",
                    value: new ServiceReleazio(
                        new RepositoryReleazio(
                            new ServiceReleazioApi(),
                        ),
                        import.meta.env.VITE_VERSION
                    )
                },
                {key: "router", value: new RouterService()},
                {key: "paymentIncome", value: new ServicePaymentIncome()},
                {key: "fetchOperation", value: new ServiceFetchOperation(new RepositoryFetchOperation())},
                {key: "telegram", value: new ServiceTelegram()},
            ])
            .build();
    }

    //TODO not used
    public async auth(token: string, phone: string) {
        localStorage.setItem("token", token);
        const response: any = await this._authorizationService.authorization(this.buildAuthDto(token, phone));
        localStorage.setItem("ltoken", response.data.token);
        localStorage.setItem("lrefreshToken", response.data.refreshToken);
        localStorage.setItem("csp", response.data.csp);

        this.setToken(token);
        MainApiService.getInstance().updateUpdateHeadersWithXToken();
        await this.init();
    }

    //TODO not used (used in the upper method)
    private buildAuthDto(token: string, phone: string) {
        const platform = !getDevice().cordova
            ? "web"
            : getDevice().os;
        const bundleId = platform === "web"
            ? null
            : "com.bromoney.app";
        const deviceId = getDevice().cordova
            // @ts-ignore
            ? device.uuid
            : generateHash(`${window.navigator.userAgent}:${phone}`);

        const userAgent = window.navigator.userAgent
            ? window.navigator.userAgent
            : null;
        const result = new AuthDto(
            token,
            deviceId,
            platform,
            bundleId,
            userAgent,
            import.meta.env.VITE_VERSION,
            []
        );

        const localFcmToken = localStorage.getItem("fcm-token");
        const fcmToken = localFcmToken ? new FirebaseFcmToken(localFcmToken) : null;
        if (fcmToken) {
            result.tokens.push(fcmToken);
        }
        return result;
    }

    get routerService(): RouterService {
        return this._routerService;
    }

    get paymentService(): PaymentService {
        return this._paymentService;
    }

    get withdrawalService(): WithdrawalService {
        return this._withdrawalService;
    }

    //TODO not used
    get paymentRequestData() {
        return this._paymentRequestData;
    }

    get isAuth() {
        return this._isAuth;
    }

    get isReady() {
        return this._isReady;
    }

    public setPaymentRequestData(payload: PaymentRequestData | null) {
        this._paymentRequestData.value = payload;
    }

    public getPaymentRequestData() {
        return this._paymentRequestData.value;
    }

    public async logout(isNotifyServer: boolean = false) {
        if (isNotifyServer) await this.logoutNotifyServer();

        this._isAuth.value = false;
        this._serviceAccount.account = null;
        this._serviceOperations.operations = null;
        SocketService.getInstance().disconnect();
        this.setToken(null);

        this.removeAlertFlagWithdrawalAlert();

        try {
            localStorage.removeItem("fcm-token");
            // @ts-ignore
            cordova.plugins.firebase.messaging.deleteToken();
        } catch (e) {

        }
    }

    private removeAlertFlagWithdrawalAlert() {
        localStorage.removeItem("is_showed_withdrawal_delay_alert")
    }

    private async logoutNotifyServer() {
        try {
            const account = this._serviceAccount.account;
            if (account.value) {
                const deviceId = getDevice().cordova
                    // @ts-ignore
                    ? device.uuid
                    : generateHash(`${window.navigator.userAgent}:${account.value!.contact.phone}`);

                await this._authorizationService.logout(deviceId);
            }
        } catch (e: any) {
            LogService.of().log("AppController@logout", e.message);
        }
    }

    public beforeInitStep() {
        this._serviceDI = this.createServiceDI();

        this._serviceDI.get<ServiceTelegram>('telegram')?.init();

        return this;
    }

    public async init() {
        try {
            let pusher = {
                host: import.meta.env.VITE_API_URL.replace('https://', ''),
                key: import.meta.env.VITE_PUSHER_KEY,
                authEndpoint: import.meta.env.VITE_API_URL + "/broadcasting/auth"
            };

            if (this.checkAuth()) this._isAuth.value = true;

            // @ts-ignore
            if (import.meta.env.VITE_USE_FIREBASE === "true") {
                await FirebaseService.of().init(async () => {
                    const config = await this.fetchFirebaseMainConfigs();
                    if (config) {
                        if (config.pusherKey) pusher.key = config.pusherKey;
                        if (config.mainApiUrl) {
                            pusher.host = config.mainApiUrl.replace('https://', '');
                            pusher.authEndpoint = config.mainApiUrl + "/broadcasting/auth"
                        }
                    }

                    if (FirebaseService.of().firestoreService) {
                        this._paymentService.timerService.updateStatusLiveTimerValues(
                            FirebaseService.of().firestoreService!.parser.makeMapForPurchaseOperationLiveTimers()
                        );
                        this._withdrawalService.timerService.updateStatusLiveTimerValues(
                            FirebaseService.of().firestoreService!.parser.makeMapForWithdrawalOperationLiveTimers()
                        );
                    }

                    await FirebaseService.of().initPushNotifications(
                        this._serviceAccount.updateDeviceTokens,
                        this.checkAuth
                    );
                });
            }

            if (this.checkAuth()) {
                await this._serviceProject.fetchProjectAccount();
                const bromoneyAccountData = this._serviceProject.projectAccount;
                LangService.getInstance().set(bromoneyAccountData.value!.lang);
                await this._serviceAccount.fetchAccount();
                const account = this._serviceAccount.account.value;

                this.checkSetPassword(bromoneyAccountData.value);

                if (account) {
                    const root = document.getElementsByTagName('html')[0];
                    root.classList.add(account.project.slug);

                    SocketService.getInstance().init(account.id, pusher).connect();
                }
            } else {
                localStorage.removeItem("token");
                localStorage.removeItem("ltoken");
                localStorage.removeItem("lrefreshToken");
                localStorage.removeItem("csp");
            }

            LogService.of().log("AppController@init", "initialized");
        } catch (e: any) {
            AppController.getInstance().setToken(null);
            LogService.of().error("AppController@init", "didn't initialized");
        } finally {
            this._isReady.value = true;
        }
    }

    private async fetchFirebaseMainConfigs() {
        try {
            const doc = FirebaseService.of().firestoreService!.getDocument(DocumentTypes.OTHERS) as DocumentOthers;
            const mainApiUrl = doc.mainApiUrl;
            const projectApiUrl = doc.projectApiUrl;
            const kodmobiApiUrl = doc.kodmobiApiUrl;
            const kodmobiApiKey = doc.kodmobiApiKey;
            const pusherKey = doc.pusherKey;

            if (mainApiUrl) {
                MainApiService.getInstance().setConfigDomain(mainApiUrl);
                ServiceMainApi.of().setConfigDomain(mainApiUrl);
            }
            if (projectApiUrl) ProjectApiService.of().setConfigDomain(projectApiUrl);
            if (kodmobiApiUrl) kodmobiApiService.getInstance().setConfigDomain(kodmobiApiUrl);
            if (kodmobiApiKey) kodmobiApiService.getInstance().setHeaderXApiKey(kodmobiApiKey);

            return {
                mainApiUrl,
                pusherKey
            }
        } catch (e) {
        }
    }

    private getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    private checkSetPassword(account: any) {
        if (account.passwordIsSet) return;

        if (typeof this.getCookie('password_notification') === "undefined") {
            const now = new Date();
            const time = now.getTime();
            const expireTime = time + 1000 * 60 * 60 * 24;
            now.setTime(expireTime);
            document.cookie = 'password_notification=exists;expires=' + now.toUTCString() + ';path=/';
            f7.views.current.router.navigate('/settings/set-password', {
                openIn: 'popup'
            });
        }
    }

    private checkAuth(): boolean {
        if (localStorage.getItem('token') !== null && localStorage.getItem('ltoken') !== null) {
            return true;
        }
        return false;
    }

    //TODO not used
    private getToken(): string | null {
        const token = localStorage.getItem('token');
        if (token) {
            return token;
        }
        return null;
    }

    public static getInstance() {
        if (AppController.instance === null) {
            AppController.instance = new AppController()
        }
        return AppController.instance
    }

    public static of() {
        if (AppController.instance === null) {
            AppController.instance = new AppController()
        }
        return AppController.instance
    }
}

import {ref, Ref} from "vue"
// @ts-ignore
import Api from "@target/services/project/projectApiService";
// @ts-ignore
import kodmobiApi from "@target/services/project/kodmobiApiService";
import i18n from "@/langs/i18n"
import {Result} from "@/components/maz/components/MazPhoneNumberInput/types";
import LangService from "@/services/LangService";
import KodmobiSettings from "@models/responses/kodmobi/KodmobiSettings";
import {plainToInstance} from "class-transformer";
import CaptchaController from "@/targets/main/views/login/CaptchaController";
import {CaptchaActions} from "@/targets/main/views/login/types/CaptchaActions";
import {CaptchaPlace} from "@/targets/main/views/login/types/CaptchaPlace";
import {PersistentTimer} from "@/helpers/timer";

export default class LoginController {
    private static instance: LoginController | null = null;
    private _captchaController: CaptchaController | null = null;
    public phoneCode: Ref<string> = ref('');
    public phone = '';
    public code: Ref<string> = ref('');
    public isSendLoading = ref(false);
    public kodMobiSessionExpiredAt = ref('');
    public flow: 'to_password' | 'to_code' | null = null;
    public password: Ref<string> = ref('');
    public channel: Ref<Channel | null> = ref(null);
    public channels: Ref<Channel[]> = ref([]);
    public channelWasChanged: boolean = false;

    private _sessionTimerText = ref<string | null>(null);
    private _sessionTimer = new PersistentTimer().onTick((remainingTime) => {
        this._sessionTimerText.value = remainingTime;
    });

    private _channelTimers = ref<Record<string, PersistentTimer | null>>({})
    private _channelTimerTexts = ref<Record<string, string | null>>({})

    public result: Ref<Result> = ref({
        isValid: false,
    });
    private _sessionID: string | null = null;
    private _verifyToken = null;
    private _kodmobiSettings: KodmobiSettings | null = null;

    private constructor() {
    }

    get captchaController(): CaptchaController | null {
        return this._captchaController;
    }

    public async checkAndInitCaptcha() {
        if (!this._kodmobiSettings) {
            throw new Error("_kodmobiSettings is null");
        }
        if (this._kodmobiSettings.challenge.enabled) {
            if (!this._captchaController) {
                this._captchaController = new CaptchaController();
            }
        }
    }

    public async generateCaptchaTokenForAction(
        action: CaptchaActions,
        selector: string,
        captchaPlace: CaptchaPlace,
        closure: Function
    ): Promise<string> {
        if (!this._kodmobiSettings) {
            throw new Error("_kodmobiSettings is null");
        }

        if (!this._captchaController) {
            throw new Error("_captchaController is null");
        }

        return await this._captchaController.generateToken(
            this._kodmobiSettings.challenge.apiKey!,
            action,
            selector,
            captchaPlace,
            () => {
                this._captchaController!.setCaptchaHeaderKodMobiApi();
                closure();
            }
        );
    }

    get kodmobiSettings(): KodmobiSettings | null {
        return this._kodmobiSettings;
    }

    get session() {
        return this._sessionID;
    }

    get sessionTimerText() {
        return this._sessionTimerText;
    }

    public static getInstance() {
        if (LoginController.instance === null) {
            LoginController.instance = new LoginController()
        }
        return LoginController.instance
    }

    public async requestCheckExistsPhoneAndSetFlow() {
        const response: any = await Api.of().post('/api/auth/v2/phone/check', {phone: this.phone});
        this.flow = response.data.flow;
    }

    public async requestAndSetKodmobiSettings() {
        const response: KodmobiSettings = await kodmobiApi.getInstance().get('/v2/settings');
        this._kodmobiSettings = plainToInstance(KodmobiSettings, response, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
    }

    /**
     * Function creates code and returns channels that can be used for send new messages
     */
    public async requestCreateSession(send = false, channelType: string | undefined = undefined): Promise<void> {
        const payload = {
            to: this.phone,
            send,
            type: channelType,
        };
        const response: KodMobiCreateResponse = await kodmobiApi.getInstance().post('/v2/create', payload);
        this._sessionID = response.sessionId;
        this.channels.value = response.channels;

        response.channels.forEach((channel: Channel) => {
            this.startTimerForChannel(channel.type, channel.timeout)
        })

        this._sessionTimer
            .reset()
            .toTimestamp(response.sessionExpiredAt)
            .start()
    }

    public async requestSendCode() {
        const payload = {
            session_id: this._sessionID,
            type: this.channel.value!.type,
        };

        const response: KodMobiSendResponse = await kodmobiApi.getInstance().post('/v2/send', payload)
        this.kodMobiSessionExpiredAt.value = response.sessionExpiredAt
        this._sessionTimer.reset().toTimestamp(response.sessionExpiredAt).start()

        const sentToChannel = response.channel
        this.startTimerForChannel(sentToChannel.type, sentToChannel.timeout)
        return response
    }

    public async requestCheckCode() {
        const payload = {
            code: this.code.value,
            session_id: this._sessionID,
        };
        const {verifyToken} = await kodmobiApi.getInstance().post('/v2/check', payload)
        this._verifyToken = verifyToken;
    }

    public setActiveChannelByName(channelToUpdate: string) {
        this.channels.value.map(el => {
            el.isActive = el.type === channelToUpdate;
        })
        this.channel.value = this.channels.value.find(el => el.isActive)!;
    }

    public getNumberPhone() {
        return `${this.result.value.countryCallingCode}${this.result.value.nationalNumber}`;
    }

    public getCountyCode() {
        return `${this.result.value.countryCode}`;
    }

    public async requestManuallySendCode(channel: string) {
        const payload = {
            session_id: this._sessionID,
            type: channel,
        };
        const response: KodMobiSendResponse = await kodmobiApi.getInstance().post('/v2/send', payload);
        this.kodMobiSessionExpiredAt.value = response.sessionExpiredAt
        this._sessionTimer.reset().toTimestamp(response.sessionExpiredAt).start()

        const sentToChannel = response.channel

        if (sentToChannel.isActive) {
            this.channel.value = sentToChannel
        }

        if (sentToChannel.timeout) {
            this.startTimerForChannel(sentToChannel.type, sentToChannel.timeout)
        }


        return response
    }


    public setChannel(channel: Channel) {
        this.channel.value = channel;
        this.channelWasChanged = true;
    }

    public setCode(code: string) {
        this.code.value = code;
    }

    public async requestVerifyToken() {
        const payload = {
            phone: this.phone,
          country_code: this.getCountyCode(),
            verify_token: this._verifyToken,
            lang: LangService.getInstance().get()
        };
        const {data} = await Api.of().post('/api/v2/auth/verify', payload);
        return data;
    }

    public async requestCheckPassword() {
        const payload = {
            phone: this.phone,
            password: this.password.value,
            lang: LangService.getInstance().get()
        };
        const response: any = await Api.of().post('/api/auth/v2', payload);
        return response.data;
    }

    public async generateCaptchaIfEnabled(
        action: CaptchaActions,
        selector: string,
        captchaPlace: CaptchaPlace,
    ) {
        await this.requestAndSetKodmobiSettings();

        if (!this._kodmobiSettings?.challenge.enabled) {
            return Promise.resolve()
        }

        await this.checkAndInitCaptcha();

        return new Promise(async (resolve) => {
            await this.generateCaptchaTokenForAction(
                action,
                selector,
                captchaPlace,
                resolve,
            )

            this.removeCaptchaIfEnabled(captchaPlace)
        })
    }

    public removeCaptchaIfEnabled(captchaPlace: CaptchaPlace) {
        if (!this._kodmobiSettings?.challenge.enabled) {
            return
        }
        this.captchaController?.deleteCaptchaId(captchaPlace)
    }

    public async checkPushEnabled() {
        // const token = localStorage.getItem('token')!;
        // const device = getDevice();
        // if (device.cordova) {
        //     // @ts-ignore
        //     cordova.plugins.diagnostic.isRemoteNotificationsEnabled(
        //         async function(enabled: any) {
        //             if (!enabled) {
        //                 f7.view.current.router.navigate({
        //                     path: '/push-ask'
        //                 })
        //             } else {
        //                 await AppController.getInstance().signin(token);
        //             }
        //         },
        //         async function(error: any) {
        //             await AppController.getInstance().signin(token);
        //         }
        //     );
        // } else {
        //     f7.view.current.router.navigate({
        //         path: '/push-ask'
        //     })
        // }
    }

    // @autobind
    public async requestAuthEmail(email: String, password: String) {
        const payload = {
            'email': email.toString(),
            'password': password.toString(),
            'device_name': 'mobile_app',
            'app_version': '0.0.0'
        }

        // const response: any = await ProjectApiService.getInstance().post(ApiRoutesMap.getTokenByEmail, payload, false)
        // // if (response.status == 'error') throw new Error(response.message)
        // const result = await transformAndValidate(ResponseAuthLogin, response.data) as ResponseAuthLogin;
        // localStorage.setItem('token', result.token)
    }

    // @autobind
    public async requestAuthGuest() {
        const body: FormData = new FormData()

        // if (localStorage.getItem('guestId')) body.append('guest_id', localStorage.getItem('guestId')!)
        // const response: any = await ProjectApiService.getInstance().post('/main/guest', body)
        // // if (response.status == 'error') throw new Error(response.message)
        // const result = await transformAndValidate(ResponseAuthGuest, response.data) as ResponseAuthGuest
        // localStorage.setItem('guestId', result.guestId.toString());
        // await AppController.getInstance().signin(result.token)
    }

    public getFirstActiveChannel() {
        const firstActiveChannel =  this.channels.value.find(channel => channel.isActive)!;

        if (!firstActiveChannel) {
            throw new Error('No active channel found.');
        }

        return firstActiveChannel;
    }

    public getTimerTextByChannel(channelType: string) {
        return this._channelTimerTexts.value[channelType];
    }

    private cleanTimers() {
        Object.values(this._channelTimers.value).map((timer) => {
            timer?.reset()
        })

        this._channelTimers.value = {}
        this._channelTimerTexts.value = {}
        this._sessionTimer.reset()
    }

    private startTimerForChannel(channelType: string, numberOfSeconds: number) {
        if (this._channelTimers.value[channelType]) {
            this._channelTimers.value[channelType]?.reset()
            this._channelTimers.value[channelType] = null
        }

        if (numberOfSeconds <= 0) {
            return
        }

        this._channelTimers.value[channelType] = new PersistentTimer()
            .reset()
            .fromSecondsNumber(numberOfSeconds)
            .onTick((remainingTime) => {
                this._channelTimerTexts.value[channelType] = remainingTime
            })
            .start()
    }

    public destroy() {
        this.phone = '';
        this.flow = null;
        this.password.value = ''
        this.code.value = '';
        this.channel.value = null;
        this.channels.value = [];
        this.password.value = '';
        this._captchaController?.reset();
        this._captchaController = null;
        this.cleanTimers()
        LoginController.instance = null;
    }
}

export type Channel = {
    description: string
    imageUrl: string
    isActive: boolean
    link: string
    name: string
    timeout: number
    type: string
}

export type KodMobiCreateResponse = {
    channels: Channel[]
    sentTo: string,
    sessionExpiredAt: string
    sessionId: string
}

export type KodMobiSendResponse = {
    channel: Channel
    sentTo: string,
    sessionExpiredAt: string
    sessionId: string
}

class ResponseCheckUserByPhone {
    public phone!: string
    public clientExist!: boolean
    public passwordIsSet!: boolean
}

class ResponseCheckCode {
    public clientIsNew!: boolean
    public passwordIsSet!: boolean
    public token!: string
}

class ResponseAuthGuest {
    public status!: String
    public guestId!: number
    public loginParameter!: String
    public token!: String
}

class ResponseAuthLogin {
    public status!: String
    public phone!: boolean
    public loginParameter!: String
    public token!: string
}

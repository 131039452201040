<template>
    <f7-page ref="self" :page-content="false" class="select-currency-page">
        <f7-navbar class="select-currency-page_navbar">
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="back">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ $t("g.settings.selectCurrency.title") }}
            </f7-nav-title>
            <f7-nav-right></f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <div class="select-currency-page_search">
                <f7-list strong-ios dividers-ios inset-ios>
                    <f7-list-input type="text" :placeholder="$t('g.search')" clear-button
                                   v-model:value="inputSearchCurrency">
                        <template #media>
                            <i class="icon search-svg"></i>
                        </template>
                    </f7-list-input>
                </f7-list>
            </div>
            <f7-list class="currencies list-dividers list-strong list-outline">
                <template v-for="currency in filteredCurrencies" :key="currency.name">
                    <f7-list-item
                        @change="updateCurrency"
                        radio
                        radio-icon="end"
                        :title="$t(`g.settings.selectCurrency.currencies.${currency.name}`)"
                        :value="currency.name"
                        name="currency"
                        :checked="client.currency == currency.name"
                    ></f7-list-item>
                </template>
            </f7-list>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { f7 } from 'framework7-vue';
import {computed, defineComponent, Ref, ref} from "@vue/runtime-core";
// @ts-ignore
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ServiceProject from "@/services/v2/data/service-project/ServiceProject";
import ModelProjectAccount from "@models/v2/project/ModelProjectAccount";

//TODO возможно уже deprecated компонент его вызов закомментирован в SettingsPage
export default defineComponent({
    setup() {
        const { t } = useI18n({ useScope: 'global' });
        const account = ServiceAccount.of().account;
        const client = ServiceProject.of().projectAccount as Ref<ModelProjectAccount>;
        const inputSearchCurrency = ref("");
        const currencies = [
            {
                "name": "RUB",
            },
            {
                "name": "KZT"
            }
        ];
        const filteredCurrencies = computed(() => {
            return currencies.filter(currency => {
                if (!inputSearchCurrency.value.trim()) {
                    return currency;
                }
                return currency.name.toLowerCase().includes(inputSearchCurrency.value.toLowerCase());
            });
        });

        return {
            filteredCurrencies,
            inputSearchCurrency,
            currencies,
            t,
            client,
            account
        }
    },
    computed: {},
    mounted() {

    },
    beforeMount() {
        // StatusBarController.toDarkContent();
    },
    methods: {
        async updateCurrency(e: any) {
            try {
                f7.preloader.show();
                await ServiceProject.of().patchProjectAccountData({currency: e.target.value});
                await ServiceAccount.of().fetchAccount();
                f7.view.main.router.back();
            } catch (e: any) {
                console.log(e)
                f7.dialog.alert(this.t("g.settings.selectCurrency.alert.first"), this.t("g.settings.selectCurrency.alert.second"));
            } finally {
                f7.preloader.hide();
            }
        },
        back() {
            f7.view.main.router.back();
        },
    },
    unmounted() {

    },
});
</script>

<style lang="scss">
.select-currency-page {
    &_navbar {
        --f7-navbar-bg-color: var(--white1);
    }

    &_search {
        background-color: var(--white1);

        .list {
            margin: 0 !important;
            padding: 12px 16px !important;
            --f7-input-item-bg-color: var(--grey6);
            --f7-list-item-min-height: 36px;

            .item-input {
                height: 36px;
                --f7-list-item-media-margin: 4px;
                border-radius: 10px;

                .search-svg {
                    width: 20px;
                    height: 20px;
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.2725 16.2988L13.3457 12.373C14.1955 11.333 14.6644 10.0234 14.6644 8.59375C14.6644 5.22754 11.9353 2.5 8.56902 2.5C5.20278 2.5 2.5 5.22842 2.5 8.59375C2.5 11.9591 5.22886 14.6875 8.56902 14.6875C9.9988 14.6875 11.3105 14.1921 12.3493 13.368L16.2762 17.2937C16.4403 17.4326 16.622 17.5 16.8007 17.5C16.9795 17.5 17.1606 17.4313 17.298 17.294C17.5714 17.0195 17.5714 16.5742 17.2725 16.2988ZM3.90663 8.59375C3.90663 6.00918 6.01013 3.90625 8.59539 3.90625C11.1807 3.90625 13.2842 6.00918 13.2842 8.59375C13.2842 11.1783 11.1807 13.2812 8.59539 13.2812C6.01013 13.2812 3.90663 11.1777 3.90663 8.59375Z' fill='%23818E95'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    .currencies {
        margin-top: 0;

        ul {
            &::before {
                content: none;
            }
        }
    }
    .title-wrapper {
        align-items: center;
        margin: 27px 16px 5px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #818E95;
        }
    }
}
</style>

import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import ProjectApiService from "@/targets/main/services/project/projectApiService";
import ModelProjectAccount from "@models/v2/project/ModelProjectAccount";

export default class RepositoryProject {
    public async fetchProjectAccount(): Promise<ModelProjectAccount> {
        const { data } = await ProjectApiService.of().get('/api/user');

        return await this.plainToModelAccount(data);
    }

    public async patchProjectAccountCurrency(payload: { currency: string }): Promise<ModelProjectAccount> {
        const { data } = await ProjectApiService.of().get(`/api/user/currency/change`, payload);

        return  await this.plainToModelAccount(data);
    }

    async patchProjectAccountData(payload: any) {
        const { data } = await ProjectApiService.of().post(`/api/user/data/patch`, payload);

        return await this.plainToModelAccount(data);
    }

    private async plainToModelAccount(data: object): Promise<ModelProjectAccount> {
        const account: ModelProjectAccount = plainToInstance(ModelProjectAccount, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(account);

        return account;
    }
}

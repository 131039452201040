<template>
    <f7-page
        class="payments-view"
        name="main"
        :page-content="false"
        @page:beforein="pageBeforeInEvent"
        @page:afterin="pageAfterInEvent"
        @page:beforeout="beforeOut"
    >
        <Navbar
            :viewNavbarBalance="viewNavbarBalance"
            :viewNavbarLogo="viewNavbarLogo"
            @openSheetModalForNewPayment="openSheetModalForNewPayment"
        />
        <f7-page-content
            ptr
            @ptr:refresh="eventPtrRefresh"
            infinite
            @infinite="eventInfinite"
        >
            <Transition name="fade" mode="out-in">
                <BalanceBlock
                    v-if="account"
                    :account="account"
                    @openSheetModalForWithdrawal="openSheetModalForWithdrawal"
                    @openSheetModalForNewPayment="openSheetModalForNewPayment"
                    @infoClick="openBalanceHintSheet"
                />
                <BalanceBlockSkeleton v-else/>
            </Transition>
            <Transition name="fade" mode="out-in">
                <template v-if="payments">
                    <Transition name="fade" mode="out-in">
                        <template v-if="payments.length">
                            <MyPayments
                                :operations="payments"
                                :account="account"
                                @toOperationIncome="toOperationIncomeHandler"
                                @toOperationPurchase="toOperationPurchaseHandler"
                                @toOperationWithdrawal="toOperationWithdrawalHandler"
                                @toOperationWithdrawalWallet="toOperationWithdrawalWalletHandler"
                            />
                        </template>
                        <template v-else>
                            <SlothBlock
                                :title="$t('g.payments.noPayments.specific.title')"
                                :subtitle="$t('g.payments.noPayments.specific.text')"
                                :type="SLOTH_TYPE.WIN"
                            />
                        </template>
                    </Transition>
                </template>
                <MyPaymentsSkeleton v-else/>
            </Transition>
        </f7-page-content>
        <no-agents-sheet/>
        <ban-sheet
            v-if="account?.settings.ban"
            ref="ban-sheet"
            :account="account"
        />
        <balance-hint-sheet v-if="isBalanceHintSheet" @closed="isBalanceHintSheet = false"/>
    </f7-page>
</template>

<script lang="ts">
import {useI18n} from 'vue-i18n'
import PaymentsViewController from "./ts/PaymentsViewController";
import {computed, defineAsyncComponent, Ref, ref} from "vue";
import {f7} from "framework7-vue";
import {getDevice} from "framework7/shared/get-device";
import BalanceBlock from '@components/atomic/balance/BalanceBlock.vue';
import BalanceBlockSkeleton from "@components/atomic/balance/BalanceBlockSkeleton.vue";
import Navbar from "@/targets/main/views/payments/components/Navbar.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import SlothBlock from "@/components/messages/SlothBlock.vue";
import AppController from "@/targets/main/components/App/ts/AppController";
import MyPaymentsSkeleton from "@components/atomic/operations/MyPaymentsSkeleton.vue";
import ErrorsService from "@/services/errors-service/ErrorsService";
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import RouterService from "@/services/RouterService";
import LogService from "@/services/log-service/LogService";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ServiceRates from "@/services/v2/data/service-rates/ServiceRates";
import ServiceOperations from "@/services/v2/data/service-operations/ServiceOperations";
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";
import ServicePaymentIncome from "@/services/v2/data/service-payment-income/ServicePaymentIncome";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import ModelAccount from "@models/v2/account/ModelAccount";
import ServiceFetchOperation from "@/services/v2/data/service-fetch-operation/ServiceFetchOperation";
import {OperationType} from "@enums/operation/OperationType";
import {FirebaseService} from "@/services/firebase/FirebaseService";

export default {
    computed: {
        SLOTH_TYPE() {
            return SLOTH_TYPE
        }
    },
    components: {
        MyPaymentsSkeleton,
        BalanceBlockSkeleton,
        SlothBlock,
        Navbar,
        BalanceBlock,
        'no-agents-sheet': defineAsyncComponent(() => import('@/components/sheets/NoAgentsSheet.vue')),
        'ban-sheet': defineAsyncComponent(() => import('@/components/sheets/BanSheet.vue')),
        'balance-hint-sheet': defineAsyncComponent(() => import('@components/sheets/BalanceHintSheet.vue')),
        'MyPayments': defineAsyncComponent(() => import('./components/MyPayments.vue')),
    },
    setup() {
        const routerService: RouterService = AppController.getInstance().routerService;
        const servicePaymentIncome: ServicePaymentIncome | null = AppController.of().service<ServicePaymentIncome>('paymentIncome');
        const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
        const {t} = useI18n({useScope: 'global'});
        const vc = PaymentsViewController.of();
        const isBalanceHintSheet = ref(false);
        const account = ServiceAccount.of().account as Ref<ModelAccount>;
        const payments = ServiceOperations.of().operations;
        const allowInfinity = false;
        const requisitePs = GlobalRequisitesController.getInstance();
        const viewNavbarBalance = ref(false);
        const viewNavbarLogo = ref(false);
        const appIsReady = AppController.getInstance().isReady;
        const totalPages = ServiceOperations.of().totalPages;
        const currentPage = ServiceOperations.of().page;
        const isDisallowFetchInfinityScroll = computed(() => currentPage.value > totalPages.value);

        return {
            routerService,
            servicePaymentIncome,
            withdrawalService,
            totalPages,
            currentPage,
            isBalanceHintSheet,
            appIsReady,
            viewNavbarLogo,
            viewNavbarBalance,
            requisitePs,
            t,
            payments,
            account,
            vc,
            allowInfinity,
            isDisallowFetchInfinityScroll
        }
    },
    watch: {
        'payments'() {
            setTimeout(() => {
                this.updateInfinityScroll();
            }, 700)
        },
        async 'appIsReady'(value: boolean) {
            try {
                if (value) {
                    await this.vc.updateAccountData();
                    await this.fetchOperations({refresh: true});
                }
            } catch (e) {
                ErrorsService.of().handle(e);
            }
        }
    },
    mounted() {
        this.updateInfinityScroll();
    },
    methods: {
        async fetchOperations(config: any) {
            try {
                await ServiceOperations.of().fetchOperations(config);
            } catch (e) {
                await ErrorsService.of().handle(e);
            }
        },
        openBalanceHintSheet() {
            this.isBalanceHintSheet = true;
        },
        //TODO not used
        TO_SETTINGS() {
            f7.views.current.router.navigate('/settings');
        },
        //TODO not used
        CLOSE() {
            switch (this.account?.settings.homeUrlType) {
                case 'webview':
                    window.open(this.account.settings.homeUrl, '_self');
                    break;
                case 'deeplink':
                    try {
                        if (getDevice().android) {
                            window.webInterface.onMessage(JSON.stringify({
                                "deeplink": this.account.settings.homeUrl
                            }));
                        }
                        if (getDevice().ios) {
                            window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                                "deeplink": this.account.settings.homeUrl
                            }));
                        }
                    } catch (e) {
                        // console.log(e);
                    }
                    break;
                default:
                    break;
            }
        },
        async pageBeforeInEvent() {
            if (this.appIsReady) {
                await this.vc.updateAccountData();
                await this.fetchOperations({refresh: true});
            }
        },
        pageAfterInEvent() {
            AppController.getInstance().setPaymentRequestData(null);
            // this.showAlertWithWithdrawalWarning();
        },
        //TODO not used
        showAlertWithWithdrawalWarning() {
            if (!localStorage.getItem("is_showed_withdrawal_delay_alert")) {
                f7.dialog.alert(this.t("alerts.withdrawal-warning-delays.text"), this.t("alerts.withdrawal-warning-delays.title"));
                localStorage.setItem("is_showed_withdrawal_delay_alert", "true")
            }
        },
        //TODO used in template, but inside commented
        beforeOut() {
            // this.vc.unSyncSocket();
        },
        updateInfinityScroll() {
            if (!this.payments || this.isDisallowFetchInfinityScroll) {
                (document.querySelector('.infinite-scroll-preloader') as HTMLDivElement).style.display = 'none';
                this.allowInfinity = false;
            } else {
                (document.querySelector('.infinite-scroll-preloader') as HTMLDivElement).style.display = 'block';
                this.allowInfinity = true;
            }
        },
        async openSheetModalForWithdrawal(callback?: Function) {
            try {
                AppController.of().withdrawalService.withdrawal = null;
                GlobalRequisitesController.getInstance().activeRequisiteAmountRange.value = null;
                // await this.requisitePs.fetchAllRequisites();
                await FirebaseService.of().firestoreService?.fetchWithdrawalDocument();
                await ServiceRates.of().fetchRates();
                // await this.requisitePs.checkActiveRequisite();
                await this.requisitePs.fetchAddressTypes();

                if (this.requisitePs.activeRequisiteAmountRange.value) {
                    const {min} = this.requisitePs.activeRequisiteAmountRange.value;

                    if (min <= 0) {
                        this.openPopupNoAgents();
                        return;
                    }
                }

                f7.views.main.router.navigate('/popup/withdrawal')
            } catch (e: any) {
                LogService.of().log(e);
                ErrorsService.of().handle(e);
            } finally {
                if (typeof callback === "function") callback();
            }
        },
        openPopupNoAgents() {
            const el: HTMLDivElement = document.querySelector('.no-agents-sheet')!;
            const sheet = f7.sheet.create({
                el,
            });
            sheet.open();
        },
        async openSheetModalForNewPayment(callback?: Function) {
            try {
                await FirebaseService.of().firestoreService?.fetchPurchaseDocument();
                await this.routerService.toCreatePaymentPopup();
            } catch (e) {
                f7.dialog.alert(this.t("g.payments.alert.first"), this.t("g.payments.alert.second"), () => {
                });
            } finally {
                if (typeof callback === "function") callback();
            }
        },
        async eventInfinite() {
            if (this.allowInfinity) {
                this.allowInfinity = false;
                await this.fetchOperations({refresh: false});
            }
        },
        async eventPtrRefresh(done: any) {
            try {
                await this.vc.updateMyAccount();
                await this.vc.updateMyPayments();
                // await store.dispatch('fetchPaymentSystems', null);
                // await store.dispatch('fetchCurrencies', null);
                this.updateInfinityScroll();
                this.allowInfinity = true;
            } catch (e: any) {
                await ErrorsService.of().handle(e);
            } finally {
                done();
            }
        },
        toOperationIncomeHandler(operation: PaymentIncomeOperation) {
            if (operation.msid) {
                AppController.getInstance().routerService.toIncomeOperationByMsid(operation.msid);
            }
        },
        async toOperationPurchaseHandler(operation: PurchaseOperation) {
            if (operation.msid) {
                await AppController.getInstance().routerService.toPaymentOperationByMsid(operation.msid);
            }
        },
        async toOperationWithdrawalHandler(operation: WithdrawalOperation) {
            try {
                f7.preloader.show();
                const modelOperation = await AppController.of()?.service<ServiceFetchOperation>("fetchOperation")
                    ?.fetchOperation({
                        type: OperationType.CLIENT_P2P_SELL,
                        msid: operation!.msid!
                    });
                AppController.of().withdrawalService.withdrawal = modelOperation;

                f7.views.main.router.navigate('/withdrawal');
            } catch (e: any) {
                ErrorsService.of().handle(e);
            } finally {
                f7.preloader.hide();
            }
        },
        async toOperationWithdrawalWalletHandler(operation: WithdrawalWalletOperation) {
            await this.routerService.toTransferOperationByMsid(operation.msid);
        }
    },
    unmounted() {
        this.vc.destructor();
    }
};
</script>

<style src="./index.scss" lang="scss"></style>

import { ref, Ref } from "vue";
import RepositoryProject from "@/repositories/v2/repository-project/RepositoryProject";
import ModelProjectAccount from "@models/v2/project/ModelProjectAccount";

export default class ServiceProject {
    private static _instance: ServiceProject | null = null;
    private readonly _projectAccount: Ref<ModelProjectAccount | null>;
    private readonly _repositoryProject: RepositoryProject;

    constructor() {
        this._projectAccount = ref(null);
        this._repositoryProject = new RepositoryProject();
    }

    public static of() {
        if (ServiceProject._instance === null) {
            ServiceProject._instance = new ServiceProject();
        }

        return ServiceProject._instance;
    }

    public get projectAccount(): Ref<ModelProjectAccount | null> {
        return this._projectAccount;
    }

    public set projectAccount(value: ModelProjectAccount | null) {
        this._projectAccount.value = value;
    }

    public async fetchProjectAccount() {
        this._projectAccount.value = await this._repositoryProject.fetchProjectAccount();
    }

    public async patchProjectAccountCurrency(context: any, payload: { currency: string }) {
        this._projectAccount.value = await this._repositoryProject.patchProjectAccountCurrency(payload);
    }

    async patchProjectAccountData(payload: any) {
        this._projectAccount.value = await this._repositoryProject.patchProjectAccountData(payload);
    }
}

<template>
    <div class="my-payments">
        <div class="title-wrapper">
            <span class="title">{{ $t("payments.myPayments") }}</span>
        </div>
        <div class="payments-list">
            <template v-for="operation in operations">
                <template v-if="operation instanceof PaymentIncomeOperation">
                    <IncomeOperationCard
                        :key="operation.status + '' + '' + operation.id"
                        :operation="operation"
                        @toOperation="toOperationIncomeHandler(operation)"
                    />
                </template>
                <template v-else-if="operation instanceof PurchaseOperation">
                    <PurchaseOperationCard
                        :key="operation.status + '' + operation.msid + '' + operation.chat?.unread"
                        :operation="operation"
                        :account="account"
                        @toOperation="toOperationPurchaseHandler(operation)"
                    />
                </template>
                <template v-else-if="operation instanceof WithdrawalOperation">
                    <WithdrawalOperationCard
                        :key="operation.status + '' + operation.msid + '' + operation.chat?.unread"
                        :operation="operation"
                        :account="account"
                        @toOperation="toOperationWithdrawalHandler(operation)"
                    />
                </template>
                <template v-else-if="operation instanceof WithdrawalWalletOperation">
                    <WithdrawalWalletOperationCard
                        :key="operation.msid"
                        :operation="operation"
                        @toOperation="toOperationWithdrawalWalletHandler(operation)"
                    />
                </template>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import IncomeOperationCard from "@components/atomic/operations/cards/IncomeOperationCard.vue";
import PurchaseOperationCard from "@components/atomic/operations/cards/PurchaseOperationCard.vue";
import WithdrawalOperationCard from "@components/atomic/operations/cards/WithdrawalOperationCard.vue";
import WithdrawalWalletOperationCard from "@components/atomic/operations/cards/WithdrawalWalletOperationCard.vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";
import ModelAccount from "@models/v2/account/ModelAccount";
import { Operations } from "@/repositories/v2/repository-operations/types";

interface Emits {
    (e: 'toOperationIncome', value: PaymentIncomeOperation): void,
    (e: 'toOperationPurchase', value: PurchaseOperation): void,
    (e: 'toOperationWithdrawal', value: WithdrawalOperation): void,
    (e: 'toOperationWithdrawalWallet', value: WithdrawalWalletOperation): void,
}

interface Props {
    operations: Operations;
    account: ModelAccount;
}

const emits = defineEmits<Emits>();

defineProps<Props>();

const toOperationIncomeHandler = (operation: PaymentIncomeOperation) => {
    emits('toOperationIncome', operation);
}

const toOperationPurchaseHandler = (operation: PurchaseOperation) => {
    emits('toOperationPurchase', operation);
}

const toOperationWithdrawalHandler = (operation: WithdrawalOperation) => {
    emits('toOperationWithdrawal', operation);
}

const toOperationWithdrawalWalletHandler = (operation: WithdrawalWalletOperation) => {
    emits('toOperationWithdrawalWallet', operation);
}
</script>

<style lang="scss">
.my-payments {
    .block-header {
        margin-top: 24px;
        margin-bottom: 8px;
    }

    .title-wrapper {
        align-items: center;
        margin: 0px 16px 5px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #818E95;
        }
    }

    margin-top: 27px;

    .payments-list {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: 0;
            right: auto;
            bottom: auto;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }

        &:after {
            content: '';
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 100%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }
}
</style>

<template>
    <f7-app v-bind="vc.config">
        <Transition name="fade" mode="out-in">
            <MainViews v-if="vc.isAuth.value"/>
            <AuthViews v-else/>
        </Transition>

        <releazio-need-update-popup/>
        <payment-feedback-cancel
            v-if="vc.paymentService.feedbackCancelService.modalIsVisible.value"
        />
        <payment-amount-changed-modal v-if="vc.paymentService.isAmountWasChangedModalVisible.value"/>
    </f7-app>
</template>

<script lang="ts" setup>
import "https://smartcaptcha.yandexcloud.net/captcha.js";
// @ts-ignore
import {f7, f7ready} from 'framework7-vue';
import AppController from "./ts/AppController";
import AuthViews from "../views/LoginView.vue";
import MainViews from "../views/MainViews.vue";
import {getDevice} from "framework7/shared/get-device";
import cordovaApp from '../../core/cordova-app.js';
import {onMounted} from "vue";
import ServiceReleazio from "@/services/v2/service-releazio/ServiceReleazio";
import ReleazioNeedUpdatePopup from "@components/popup/popup-releazio/ReleazioNeedUpdatePopup.vue";
import LangService from "@/services/LangService";

import {FirebaseService} from "@/services/firebase/FirebaseService";
import FirebaseNotificationCordovaPluginService from "@/services/firebase/FirebaseNotificationCordovaPluginService";
import {FirebaseNotificationCordovaHandlePush} from "@/services/firebase/FirebaseNotificationCordovaHandlerPush";
import {plainToInstance} from "class-transformer";
import FirebasePushNotificationPayload from "@models/firebase/push-notification/FirebasePushNotificationPayload";
import PaymentFeedbackCancel from "@components/modals/payment-feedback-cancel/PaymentFeedbackCancel.vue";
import PaymentService from "@/services/operations/payment/PaymentService";
import PaymentAmountChangedModal from "@components/modals/payment-amount-changed/PaymentAmountChangedModal.vue";

const vc = AppController.of();
vc.beforeInitStep().init();

onMounted(async () => {
    f7ready(async () => {
        if (getDevice().cordova) {
            cordovaApp.init(f7);
        }
        runCheckReleazioVersion();

        // setTimeout(() => {
        //     AppController.of().initCaptcha(
        //         "ysc1_4uvPdYhTEWW5lkLUW6BU1TFrS8prYy1h6mGBQgFy8b8f5525",
        //         (token: string) => {
        //             console.log(token);
        //         }
        //     );
        // }, 3000);

        // setInterval(() => {
        //     const payload = {
        //         "type": "ClientBuyOrderStatusChangedNotification",
        //         "client_id": 1012048,
        //         "operation": {
        //             "type": "client_p2p_buy",
        //             "msid": "bro_k2IajwwU",
        //             "id": "755271",
        //             "state": "ACCEPTED"
        //         }
        //     }
        //     const payloadAsClass = plainToInstance(FirebasePushNotificationPayload, payload, {
        //         excludeExtraneousValues: true,
        //         excludePrefixes: ['_']
        //     });
        //     const a = new FirebaseNotificationCordovaHandlePush();
        //     a.handle(() => {
        //         if (localStorage.getItem('token') !== null && localStorage.getItem('ltoken') !== null) {
        //             return true;
        //         }
        //         return false;
        //     }, payloadAsClass);
        // }, 10000);
    });
});

async function runCheckReleazioVersion() {
    const serviceRealizio = AppController.of().service<ServiceReleazio>("releazio");
    if (serviceRealizio) {
        await serviceRealizio.fetchVersionConfig(LangService.getInstance().get());
        serviceRealizio.updateViewPost();
        serviceRealizio.checkCurrentVersionOld();

        if (serviceRealizio.isNeedUpdate.value) {
            serviceRealizio.openNeedUpdatePopup();
        }
    }
}

</script>

<style lang="scss">
.captcha-view {
    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99998;
        background: rgba(0, 0, 0, 0.4);
        height: 100%;
        width: 100%;
    }
    &__smart-captcha {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 99999;
        height: 122px;
        width: 300px;

    }
    iframe {
        background-color: #FFF;
        border-radius: 6px;
    }
}
</style>
